import { i18nInstance } from "@preem/core";
import { PageProps } from "gatsby";
import * as React from "react";
import { useEffect } from "react";
import { I18nextProvider, useTranslation } from "react-i18next";
import { TPageContext } from "../types/pages";
import { GlobalContextProvider } from "@preem/core/contexts";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { LazyMotion } from "framer-motion";

type TWrapPageElementProps = {
  children: React.ReactNode;
  props: PageProps<Record<string, unknown>, TPageContext>;
};

export const WrapPageElement = ({ children, props }: TWrapPageElementProps) => {
  const locale = props.pageContext.locale;
  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [locale]);

  return (
    <div className={`preem-com`}>
      <div className={`preem-ds-context`}>
        <div className={`preem-ds-components`}>
          <div id="portalContainer" />
          {children}
        </div>
      </div>
    </div>
  );
};

type TWrapRootElementProps = {
  children: React.ReactNode;
};

const loadFeatures = () => import("./../components/async-files/framer-async").then((res) => res.default);

export const WrapRootElement = ({ children }: TWrapRootElementProps) => {
  const queryClient = new QueryClient();

  return (
    <GlobalContextProvider>
      <QueryClientProvider client={queryClient}>
        <I18nextProvider i18n={i18nInstance}>
          <LazyMotion features={loadFeatures}>{children}</LazyMotion>
        </I18nextProvider>
      </QueryClientProvider>
    </GlobalContextProvider>
  );
};
